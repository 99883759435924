
<template>
  <div>
    <div class="job-info">
      <div class="container">
        <div v-if="loading">
          <a-spin />
        </div>
        <template v-else>
          <div class="content">
            <div class="job-name">{{ L(detail.name) }}</div>
            <div class="job-description">
              <span class="job-salary">招聘人数：{{ detail.plan_num }}人</span>
              <span>{{ L(detail.major) }}</span>
            </div>

            <div class="tags">
              <!-- <div :title="L('专业')">
                <i class="ri-book-mark-line ri-fw"></i> {{ L(detail.major) }}
              </div>
              <div :title="L('住宿')">
                <i class="ri-home-smile-line ri-fw"></i>
                {{ L(detail.room_detail) }}
              </div>
              <div :title="L('实习薪资')">
                <i class="ri-money-cny-circle-line ri-fw"></i>
                <span v-if="parseInt(detail.salary) > 0"
                  >{{ detail.salary }}{{ L("元/月") }}</span
                >
                <span v-else>{{ L("无补贴") }}</span>
              </div>
              <div :title="L('保险')">
                <i class="ri-shield-user-line ri-fw" :title="L('保险')"></i>
                {{ L(detail.insure_detail) }}
              </div> -->
            </div>
          </div>
          <div>
            <div>
              <button
                class="collection"
                :class="isCollected ? 'active' : ''"
                @click="addCollection"
              >
                {{ isCollected ? L("取消收藏") : L("收藏") }}
              </button>
              <button class="collection delivery" @click="signUp">
                {{ detail.is_submit === "1" ? L("已投递") : L("立即投递") }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="job-wrapper" v-if="!loading">
      <div class="container">
        <div class="job">
          <div class="job-title">{{ L("职缺类型") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.type) }}
          </div>

          <div class="job-title">{{ L("职缺详情") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.job_detail) }}
          </div>

          <div class="job-title">{{ L("专业要求") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.major_detail) }}
          </div>

          <div class="job-title">{{ L("工作地点") }}</div>
          <div class="job-address" style="margin-bottom: 24px">
            <span style="color: #004BB0; font-weight: bold; margin-right: 20px;"
              >{{ L(company.province) }}-{{ L(company.city) }}-{{
                L(company.area)
              }}</span
            >
            <span>{{ L(company.address) }}</span>
          </div>

          <div v-if="detail.probation_detail" class="job-title">{{ L("实习期") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.probation_detail) }}
          </div>

          <div class="job-title">{{ L("住宿") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.room_detail) }}
          </div>

          <div v-if="detail.salary" class="job-title">{{ L("补贴") }}</div>
          <div v-if="parseInt(detail.salary) > 0" style="margin-top: 12px; margin-bottom: 24px">
            {{ detail.salary }}{{ L("元/月") }}
          </div>
          <div v-else style="margin-top: 12px; margin-bottom: 24px">
            {{ L("无") }}
          </div>

          <div v-if="detail.meal_detail" class="job-title">{{ L("伙食") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.meal_detail) }}
          </div>

          <div v-if="detail.salary_detail" class="job-title">{{ L("薪资") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.salary_detail) }}
          </div>

          <div v-if="detail.insure_detail" class="job-title">{{ L("保险") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.insure_detail) }}
          </div>

          <div v-if="detail.remarks" class="job-title">{{ L("其他") }}</div>
          <div style="margin-top: 12px; margin-bottom: 24px">
            {{ L(detail.remarks) }}
          </div>

          <div style="padding-top: 30px"></div>
          <div>
            <div class="striped-title">{{ L("企业信息") }}</div>

            <div class="company">


              <div style="font-size:18px;color:rgb(88, 129, 255);cursor:pointer" @click="openCompany(company)">{{ L(company.name) }}</div>
            </div>

            <div class="base-info">{{ L(company.business) }}</div>

            <div class="company-info">
              <div class="company-info-item">
                <div class="value">
                  {{ company.type }}
                </div>
                <div>{{ L("企业类型") }}</div>
              </div>
              <div class="company-info-item">
                <div class="value">{{ L(company.area) }}</div>
                <div>{{ L("所在区域") }}</div>
              </div>
              <div class="company-info-item">
                <div class="value">{{ company.job_num }}</div>
                <div>{{ L("招聘职位") }}</div>
              </div>
            </div>
          </div>

          <div v-if="company.lng" class="striped-title">{{ L("地理位置") }}</div>
          <div v-if="company.lng" class="map-panel">
            <div id="map"></div>
          </div>
        </div>

        <div class="extra-info">
          <AdList />
          <RecommendedPosition :list="otherList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdList from "@/components/ad-list";
import RecommendedPosition from "@/components/recommended-position";
import { fetchDetail } from "@/api/job";
import { addorCancelCollection, isCollect, signUpJob } from "@/api/me";
import checkResume from './check-resume';

export default {
  components: {
    AdList,
    RecommendedPosition,
  },

  data() {
    return {
      loading: false,

      detail: {},
      company: {},
      adList: [],
      otherList: [],

      isCollected: false, // 是否收藏
    };
  },

  watch: {
     "$route"() {
        const { query } = this.$route;
        const { uuid } = query || {};
        if (uuid) {
          this.getDetail(uuid);
        }
    },
  },

  mounted() {
    const { query } = this.$route;
    const { uuid } = query || {};
    if (uuid) {
      this.getDetail(uuid);
    }
  },

  beforeDestroy() {
    if (this.map) {
      this.map.destroy();
    }
  },

  methods: {
    getDetail(uuid) {
      this.loading = true;
      fetchDetail({
        uuid,
      })
        .then((res) => {
          if (res.job) {
            this.detail = res.job;
            this.getCollection();
          }
          if (Array.isArray(res.others)) {
            this.otherList = res.others;
          }
          if (res.company) {
            this.company = res.company;
          }
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setMap();
          }, 1000);
        });
    },

    setMap() {
      if (!window.BMapGL) {
        return;
      }
      if (!this.company.lng) {
        return;
      }
      const BMapGL = window.BMapGL;
      var map = new BMapGL.Map("map");
      var point = new BMapGL.Point(this.company.lng, this.company.lat);
      map.centerAndZoom(point, 15);

      // 创建点标记
      var marker = new BMapGL.Marker(point);
      map.addOverlay(marker);

      this.map = map;
    },

    getCollection() {
      isCollect({
        uuid: this.detail.uuid,
      }).then((res) => {
        console.log("res", res);
        if (res.info) {
          this.isCollected = true;
        } else {
          this.isCollected = false;
        }
      });
    },

    addCollection() {
      addorCancelCollection({
        uuid: this.detail.uuid,
        name: this.detail.name,
        type: "JOB",
        // type  取值  COMPANY   JOB
      }).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.getCollection();
        }
      });
    },

    signUp() {

      const resumeStr = window.localStorage.getItem('resume')
      if(resumeStr) {
        const resume = JSON.parse(resumeStr)
        const flag = checkResume(resume)
        if (flag) {
          const that  = this;
          this.$confirm({
              title: this.L("请补充简历后投递，是否去填写？"),
              onOk() {
                  that.$router.push('/me')
              },
          });
          return;
        }
      }

      if (this.detail.is_submit === "1") {
        console.log("已投递");
        return;
      }

      signUpJob({
        uuid: this.detail.uuid,
      }).then((res) => {
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
          this.getDetail(this.detail.uuid);
        }
      });
    },

    openCompany(item) {
      this.$router.push("/company-detail?uuid=" + item.uuid);
    },
  },
};
</script>



<style lang="less" scoped>
.job-info {
  background: #004BB0;
  padding-bottom: 34px;
}
.job-info .container {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
}
.job-info .content {
  flex: 1;
}
.job-info .job-name {
  font-size: 28px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 10px;
}

.job-info .job-description {
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.job-info .job-salary {
  font-size: 22px;
  font-weight: 600;
  color: #fabb1c;
  margin-right: 10px;
}

.job-info .tags {
  display: flex;
  flex-wrap: wrap;
}
.job-info .tags > div {
  min-width: 70px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 8px;
  margin-right: 8px;
  background: rgba(32, 49, 127, 0.05);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.job-info .collection {
  width: 136px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 400;
  color: #004BB0;
  border: 2px solid #004BB0;
  box-shadow: 0px 0px 10px rgba(58, 218, 162, 0.2);
  background-color: #fff;
  margin-bottom: 40px;
  cursor: pointer;
}

.job-info .active {
  background-color: #004BB0;
  color: #fff;
}

.job-info .delivery {
  margin-left: 24px;
  background: #004BB0;
  color: #fff;
}
.job-info .collection:hover {
  color: #fff;
  background-color: #004BB0;
}

.job-info .share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}
.job-info .icon {
  margin-left: 12px;
  height: 18px;
  cursor: pointer;
}

.job-wrapper {
  background-color: #f3f3f3;
  padding-top: 25px;
  padding-bottom: 36px;
}

.job-wrapper .container {
  background-color: #fff;
  display: flex;
  padding: 24px;
}

.job {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 24px;
  padding-right: 24px;
}

.job .job-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.job .publisher {
  display: flex;
  align-items: center;
  margin-bottom: 44px;
  margin-top: 16px;
}

.job .publisher-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
}
.job .publisher-name {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 4px;
}
.job .publisher-description {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.job p {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
  opacity: 0.5;
  padding: 10px 0;
}

.job .job-address {
  padding-top: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.company {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 24px;
}
.company > img {
  width: 60px;
  height: 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.company-address {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 16px;
}

.striped-title {
  font-size: 14px;
  color: #000;
  opacity: 0.8;
  font-weight: bold;
  padding-bottom: 14px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.base-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 32px;
}
.base-info img {
  width: 18px;
  margin-right: 20px;
}

.map-panel {
  height: 187px;
  overflow: hidden;
}

#map {
  height: 230px;
}

.extra-info {
  width: 270px;
}

.company-info {
  display: flex;
  padding-bottom: 24px;
}
.company-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  position: relative;
  margin-right: 40px;
}
.company-info-item::after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 60%;
  right: -20px;
  width: 1px;
  background-color: #000;
}
.company-info-item:last-child {
  margin-right: 0;
}
.company-info-item:last-child::after {
  width: 0;
}
.company-info-item .value {
  font-size: 20px;
  color: #fabb1c;
  margin-bottom: 8px;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .job-info {
    background: #004BB0;
    padding: 0 12px 12px;
  }

  .job-info .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }
  .job-info .collection {
    margin-top: 12px;
    margin-bottom: 0;
    cursor: pointer;
  }

  .job-wrapper {
    background-color: #f3f3f3;
    padding: 12px;
  }

  .job-wrapper .container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .job {
    flex: 1;
    border-right-width: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .job .job-address {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }

  .extra-info {
    width: 100%;
  }
}
</style>
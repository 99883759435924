export default function checkResume(info) {

    let flag = false;

    if (!info.name) {
        flag = true;
    }
    if (!info.sex) {
        flag = true;

    }
    if (!info.email) {
        flag = true;

    }
    if (!info.mobile) {
        flag = true;

    }


    if (!info.school) {
        flag = true;
    }
    if (!info.dept) {
        flag = true;
    }
    if (!info.grade) {
        flag = true;
    }


    if (!info.birthday) {
        flag = true;

    }
    if (!info.province) {
        flag = true;

    }
    if (!info.permit_account) {
        flag = true;
    }

    if (!info.contact_username) {
        flag = true;
    }

    return flag;


}